import React from "react";
import "../styles/Header.css";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import Logo from "../assets/Koduor & Co. Ltd Logo White.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" fixed="top" className={`navbar`}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width="70"
              height="70"
              className="d-inline-block align-top"
              alt="KC logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Item>
                <Dropdown alignRight="true">
                  <Dropdown.Toggle id="brand-dropdown">
                    <span>Media and Entertainment</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/brand-dynamics-advertising">
                      Brand Centric Dynamics Advertising & Marketing Agency
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/brand-dynamics-event">
                      Brand Dynamics Event & Promotions
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/nu-media">
                      NU Media
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
              <div className="navbar-divider">|</div>
              <Nav.Link as={Link} to="/transport">
                Transport and Logistics{" "}
              </Nav.Link>
              <div className="navbar-divider">|</div>
              <Nav.Link as={Link} to="/portable-kitchens">
                Portable Kitchens
              </Nav.Link>
              <div className="navbar-divider">|</div>
              <Nav.Link as={Link} to="/fika-innovation">
                The Koduor Trust
              </Nav.Link>
              <div className="navbar-divider">|</div>
              <Nav.Link as={Link} to="/job-vacancies">
                Job Vacancies
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
