import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import jobData from "../../jobs.json"; // Import the job data
import { Form, Button, Container, Alert } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";

const ApplyPage = () => {
  const { id } = useParams(); // Get the job ID from the URL params
  const [job, setJob] = useState(null);
  const [resume, setResume] = useState(null);
  const [video, setVideo] = useState(null);
  const [portfolio, setPortfolio] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Find the job by ID from the local data
    const jobDetail = jobData.find((job) => job.id === parseInt(id));
    setJob(jobDetail);
  }, [id]);

  const handleResumeUpload = (event) => {
    const file = event.target.files[0];
    setResume(file);
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    setVideo(file);
  };

  const handleSubmitApplication = async (event) => {
    event.preventDefault();

    if (!name || !gender || !resume) {
      setMessage("Please fill all required fields.");
      setShowAlert(true);
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("gender", gender);
    formData.append("jobTitle", job.title);
    formData.append("resume", resume);
    if (job.title === "News Anchor" && video) {
      formData.append("video", video);
    }
    if (job.title === "Graphic Designer" && portfolio) {
      formData.append("portfolio", portfolio);
    }

    try {
      const response = await fetch("http://localhost:5000/api/apply", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setMessage("Application submitted successfully!");
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setMessage("Failed to submit application.");
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      setMessage("Error submitting application. Please try again later.");
      setShowAlert(true);
    }
  };

  if (!job) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />
      <Container style={{ marginTop: "20px" }}>
        <h1>Apply for {job.title}</h1>
        <p>{job.description}</p>

        <Form onSubmit={handleSubmitApplication}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formGender" className="mt-3">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formResume" className="mt-3">
            <Form.Label>Upload Your CV</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleResumeUpload}
              required
            />
          </Form.Group>

          {/* Conditional upload section for News Anchor */}
          {job.title === "News Anchor" && (
            <Form.Group controlId="formVideo" className="mt-3">
              <Form.Label>Upload Video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                onChange={handleVideoUpload}
              />
            </Form.Group>
          )}

          {/* Conditional input section for Graphic Designer */}
          {job.title === "Graphic Designer" && (
            <Form.Group controlId="formPortfolio" className="mt-3">
              <Form.Label>Portfolio Link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter your portfolio link"
                value={portfolio}
                onChange={(e) => setPortfolio(e.target.value)}
              />
            </Form.Group>
          )}

          <Button
            type="submit"
            className="custom-button"
            style={{ marginTop: "10px" }}
          >
            Submit Application
          </Button>
        </Form>

        {showAlert && (
          <Alert
            variant={message.includes("successfully") ? "success" : "danger"}
            className="mt-4"
          >
            {message}
          </Alert>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ApplyPage;
