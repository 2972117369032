import "./styles/App.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Home from "./components/Home";
import { lazy, Suspense } from "react";
import Fika from "./components/Fika/Fika";
import NUMedia from "./components/Brand Dynamics/NUMedia";
import PortableKitchen from "./components/Portable Kitchen/PortableKitchen";
import Transport from "./components/Transport/Transport";
import JobDetails from "./components/Job Vacancies/JobDetails";
import ApplyPage from "./components/Job Vacancies/ApplyPage";

const Advertising = lazy(() =>
  import("./components/Brand Dynamics/Advertising")
);
const Event = lazy(() => import("./components/Brand Dynamics/Event"));
const JobVacancies = lazy(() =>
  import("./components/Job Vacancies/JobVacancies")
);

function App() {
  return (
    <HashRouter>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/brand-dynamics-advertising" element={<Advertising />} />
          <Route path="/brand-dynamics-event" element={<Event />} />
          <Route path="/nu-media" element={<NUMedia />} />
          <Route path="/transport" element={<Transport />} />
          <Route path="/portable-kitchens" element={<PortableKitchen />} />
          <Route path="/fika-innovation" element={<Fika />} />
          <Route path="/job-vacancies" element={<JobVacancies />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/job-application/:id" element={<ApplyPage />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
