import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jobData from "../../jobs.json";
import { Button, Container } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";

const JobDetails = () => {
  const { id } = useParams(); // Get the job ID from URL params
  const [job, setJob] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Find the job by its ID
    const jobDetail = jobData.find((job) => job.id === parseInt(id));
    setJob(jobDetail);
  }, [id]);

  const handleApplyClick = () => {
    // Navigate to the apply page
    navigate(`/job-application/${id}`);
  };

  if (!job) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Header />

      <Container>
        <h1 style={{ alignContent: "center" }}>{job.title}</h1>

        {job.company && (
          <p>
            <strong>Company:</strong> {job.company}
          </p>
        )}
        {job.location && (
          <p>
            <strong>Location:</strong> {job.location}
          </p>
        )}
        {job.reportsTo && (
          <p>
            <strong>Reports To:</strong> {job.reportsTo}
          </p>
        )}

        {job.summary && (
          <>
            <h3>Job Summary</h3>
            <p>{job.summary}</p>
          </>
        )}

        {job.responsibilities && job.responsibilities.length > 0 && (
          <>
            <h3>Key Responsibilities</h3>
            <ul>
              {job.responsibilities.map((responsibility, index) => {
                // Check if the line starts with a number (e.g., "1.", "2.") to determine the indent level
                const isIndented = responsibility.match(/^\d\./) === null;
                return (
                  <li
                    key={index}
                    style={{ marginLeft: isIndented ? "20px" : "0" }}
                  >
                    {responsibility}
                  </li>
                );
              })}
            </ul>
          </>
        )}

        {job.qualifications && job.qualifications.length > 0 && (
          <>
            <h3>Qualifications and Experience</h3>
            <ul>
              {job.qualifications.map((qualification, index) => (
                <li key={index}>{qualification}</li>
              ))}
            </ul>
          </>
        )}

        {job.skills && job.skills.length > 0 && (
          <>
            <h3>Key Skills</h3>
            <ul>
              {job.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </>
        )}

        {job.employmentTerms && (
          <>
            <h3>Employment Terms</h3>
            <ul>
              {job.employmentTerms.map((employmentTerm, index) => (
                <li key={index}>{employmentTerm}</li>
              ))}
            </ul>
          </>
        )}

        <h4>Send your CV and any necessary details to contactus@koduor.com</h4>
      </Container>
      <Footer />
    </>
  );
};

export default JobDetails;
